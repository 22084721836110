body{
  background-color: #f1f2f9 !important;
}
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Zain;
    min-height: 25vh; /* Reduced height */
    height: 30vh;
    width: 25vw; /* Increased width */
    text-align: center;
    padding: 1rem; /* Reduced padding */
    border-radius: 30px;
    background: #e0eaf7;
    box-shadow: 20px 20px 60px #bebebe,
                  -20px -20px 60px #ffffff;
    margin: 2rem auto; /* Centered with margin */

  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: static;
    max-width: 240px;
    font-weight: bold;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    margin-top: 15px;
    text-align: left;
    font-size: large;
  }
  
  .form-group input {
    width: calc(100% - 20px);
  padding: 10px;
  margin: 0 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  }
  
  .form-group input:focus {
    outline: none;
  }
  
  .submit-button {
    font-family: inherit;
    font-size: 20px;
    background: royalblue;
    color: white;
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
  }
  
  .submit-button span{
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }

  .submit-button svg{
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }

  .submit-button .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }

  .submit-button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .submit-button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .submit-button:hover span {
    transform: translateX(5em);
  }

  .submit-button:active {
    transform: scale(0.95);
  }
  

  